<template>
    <div class="columns is-multiline">
        <div class="column is-full">
            <div class="card">
                <header class="card-header">
                    <p class="card-header-title">More Tools Coming Soon!</p>
                </header>

                <div class="card-content">
                    <div class="content">
                        <p>
                            We are always striving to provide the tools you need to help with your brews. Let us know if
                             you have ideas for something new!.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {};
        }
    };
</script>